import {Injectable, Renderer2} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Subject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class GoogleMapsService {

	private script;

	private isReady = new Subject();

	constructor() {
	}

	init(renderer2: Renderer2) {
		this.script = renderer2.createElement('script');

		renderer2.setAttribute(this.script, 'src', `https://maps.googleapis.com/maps/api/js?key=${environment.googleMaps}&libraries=places`);
		renderer2.setAttribute(this.script, 'type', 'text/javascript');

		renderer2.appendChild(document.head, this.script);
	}

	isScriptReady() {
		this.checkIfReady();

		return this.isReady;
	}

	private checkIfReady() {
		setTimeout(() => {
			try {
				if (!google) {
					this.checkIfReady();
					return;
				}

				this.isReady.complete();
			} catch (e) {
				this.checkIfReady();
				return;
			}
		}, 200);
	}
}
